body {margin: 0; font-size: 14px; color: #222 !important;}
h1,h2,h3,h4,h5,h6 {margin: 10px 0;}
h1 {line-height: 43px;}
h2 {line-height: 35px;}
h3 {line-height: 30px;}
h4 {line-height: 22px;font-size: 1.8rem;}
h1,h2,h3,h4,h5,h6 {font-family: 'Roboto', sans-serif;}
* {outline: none !important;box-sizing: border-box;letter-spacing: 1px; font-family: 'Nunito Sans',sans-serif;}

.srv-validation-message{color: red; margin-top: 2px;}
.form-control:focus{box-shadow: none;}
.card{border-color: #e6ebf3;}
.m-0{margin: 0px;}
.mt-5{margin-top: 5px;}
.mt-10{margin-top: 10px;}
.mt-15{margin-top: 15px;}
.mt-20{margin-top: 20px;}
.mt-25{margin-top: 25px;}
.mb-0{margin-bottom: 0px;}
.mb-5{margin-bottom: 5px;}
.mb-10{margin-bottom: 10px;}
.mb-15{margin-bottom: 15px;}
.mb-20{margin-bottom: 20px;}
.mb-25{margin-bottom: 25px;}
.mr-0{margin-right: 0px;}
.mr-5{margin-right: 5px;}
.mr-10{margin-right: 10px;}
.mr-15{margin-right: 15px;}
.mr-20{margin-right: 20px;}
.mr-25{margin-right: 25px;}
.p-5{padding: 5px;}
.p-10{padding: 10px;}
.p-15{padding: 15px;}
.p-20{padding: 20px;}
.p-25{padding: 25px;}
.p-30{padding: 30px;}
.p-35{padding: 35px;}
.pr-5{padding-right: 5px;}
.pr-10{padding-right: 10px;}
.pr-15{padding-right: 15px;}
.pr-20{padding-right: 20px;}
.pl-5{padding-left: 5px;}
.pl-10{padding-left: 10px;}
.pl-15{padding-left: 15px;}
.pl-20{padding-left: 20px;}
.pb-5{padding-bottom: 5px;}
.pb-10{padding-bottom: 10px;}
.pb-15{padding-bottom: 15px;}
.pb-20{padding-bottom: 20px;}
a{text-decoration:none; color: #222; font-weight: normal;}
a:hover{text-decoration:none;}

.c-btn-primary{background-color: #233b90;border-color: #233b90;border-radius: 30px;padding: 7px 20px;}
.c-btn-primary:hover{background-color: #233b90eb;border-color: #233b90;}
.c-btn-danger{background-color: #dc3545;border-color: #dc3545;border-radius: 30px;padding: 7px 20px;}
.c-btn-danger:hover{background-color: #dc3545eb;border-color: #dc3545;}
.btn:focus{box-shadow: none;}

/* auth */
.auth_page{width: 100%;display: flex;}
.auth_page .left_bar, .auth_page .right_bar{width: 50%;float: left;height: 100vh; padding: 25px;}
.auth_page .left_bar{background-color: #eef9fe;}
.auth_page .left_bar img{position: relative;top: 50%;left: 50%;transform: translate(-50% , -50%);width: 250px;}
.auth_page .right_bar .form_box{  max-width: 450px;margin: 20% auto;}
.auth_page .right_bar .form_box form{margin-top: 50px;}

/* nav bar top */
.nav_bar{background-color: #fff !important; color: #8c8c8c !important; box-shadow: none !important; border: 1px solid #e6ebf3;display: flex;width: 100%;}
.nav_bar .nav_bar_left, .nav_bar .nav_bar_right{padding: 10px 0px;}
.nav_bar .nav_bar_left{width: 249px; float: left; border-right: 1px solid #e6ebf3;}
.nav_bar .nav_bar_right{width: calc(100vw - 250px); float: left;}
.nav_bar .nav_bar_right .profile{margin-left: auto;}

/* left menu */
.main_body{width: 100%; display: flex;}
.main_body .left_nav_bar{width: 250px;float: left;border-right: 1px solid #e6ebf3; height: calc(100vh - 62px);overflow-y: auto;overflow-x: hidden;}
.main_body .web_body{width: calc(100vw - 250px); height: calc(100vh - 62px); overflow: auto;}
.sidebar-menu{list-style: none;margin: 10px 0px 0px 0px;padding: 0px;}
.sidebar-menu li > a{padding: 10px 15px; display: flex;border-right: 2px solid #fff;}
.sidebar-menu li > a.active, .sidebar-menu li > a:hover{color: #41bff2;border-right: 2px solid #41bff2;background-color: #41bff217;text-decoration: none;}
.sidebar-menu li > a > span{margin-top: 5px; display: block;width: 100%;}
.sidebar-menu li svg{margin-right: 10px;}
.sidebar-menu li ::after{margin-top: 14px !important;}
.sidebar-menu li ul li a{padding-left: 45px;}

.hide_menu .nav_bar .nav_bar_left, .hide_menu .main_body .left_nav_bar{display: none;}
.hide_menu .nav_bar .nav_bar_right, .hide_menu .main_body .web_body {width: 100%;}

.toggle.ios, .toggle-on.ios, .toggle-off.ios { border-radius: 20rem; }
.toggle.ios .toggle-handle { border-radius: 20rem; }

 /* custome check box */
.switch {position: relative;display: inline-block;width: 50px;height: 25px;}
.switch input { opacity: 0;width: 0;height: 0;}
.slider {position: absolute;cursor: pointer;top: 0;left: 0;right: 0;bottom: 0;background-color: #ccc;-webkit-transition: .4s;transition: .4s;}
.slider:before {position: absolute;content: "";height: 17px;width: 17px;left: 4px;bottom: 4px;background-color: white;-webkit-transition: .4s;transition: .4s;}
input:checked + .slider {background-color: #41BFF2;}
input:focus + .slider {box-shadow: 0 0 1px #41BFF2;}
input:checked + .slider:before {-webkit-transform: translateX(26px);-ms-transform: translateX(26px);transform: translateX(26px);}
.slider.round {border-radius: 34px;}
.slider.round:before {border-radius: 50%;}
table tr td {vertical-align: middle !important;}
.modal-lg {max-width: 1140px;}

.full_screen_loader{position: fixed;height: 100%;width: 100%;top: 0px;left: 0px;z-index: 9999;background-color: #eaeaea57;}
.full_screen_loader .spinner-border{position: absolute;top: 50%;left: 50%;}

.seller-table-row{background-color: #fff; margin: 0px 0px 10px 0px; border-radius: 10px;border: 1px solid #eaeaea;}
.seller-table-row .table-td{padding: 10px;}
.seller-table-row .table-td p{line-height: normal;font-weight: 500;}

.property_box{height: 100%;border-radius: 10px !important; overflow: hidden;}
.property_box .img_box{border-radius: 0px 0px 10px 10px; overflow: hidden;}
.property_box .img_box .slick-initialized .slick-slide > div{height: 200px;}
.property_box .img_box .slick-initialized .slick-slide > div img{height: 100%; width: 100%;}


.Image_man_box{border: 1px dashed rgb(205, 207, 206);border-radius: 8px;padding: 15px;display: inline-block;width: 100%;overflow: auto; height: 425px;}
.Image_man_box .img_box{width: 170px;height: 170px;float: left;margin: 10px;border: 1px solid #ededed;padding: 10px;justify-content: center;display: flex;align-items: center;border-radius: 5px;position: relative; overflow: hidden;}
.Image_man_box .img_box span{position: absolute;top: 0px;right: 0px;padding: 0px 5px;cursor: pointer;}
.Image_man_box .img_box.add{cursor: pointer;}
.input-remove-icon{position: relative;}
.input-remove-icon .icon{position: absolute;top: 1px;width: 36px;text-align: center;height: 36px;font-size: 17px;padding: 5px;right: 1px;background-color: #fff;border-radius: 0px 3px 3px 0px;cursor: pointer;}
.pointer{cursor: pointer;}

.c-accordion{border: 1px solid rgba(0,0,0,.125); border-radius: 5px;}
.c-accordion .title{padding: 10px 15px;}
.c-accordion .body{padding: 10px 15px; display: none;}
.c-accordion .body .sub-title{border: 1px solid rgba(0,0,0,.125); border-radius: 5px;}
.c-accordion .title svg{transform : rotate(-90deg);}
.c-accordion.open .title svg{transform : rotate(0deg);}
.c-accordion.open .body{display: block;}


.nav_bar .menu_btn{position: absolute;left: 260px; z-index: 99; top: 10px;}
.hide_menu .nav_bar .menu_btn{position: absolute;left: 15px;}
@media (max-width: 1000px) {
    .auth_page{display: inline-block;}
    .auth_page .left_bar, .auth_page .right_bar{width: 100%;}
}
@media (max-width: 768px) {
    .nav_bar .nav_bar_left, .main_body .left_nav_bar{position: fixed; background-color: #fff;z-index:9}
    .nav_bar .nav_bar_right, .main_body .web_body{width: 100%;}
    .dataTables_wrapper .dataTables_length, 
    .dataTables_wrapper .dataTables_filter,
    .dataTables_wrapper .dataTables_info,
    .dataTables_wrapper .dataTables_paginate
    {width: 100% !important;}
}